import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      isBoss: this.$store.state.currentAdmin.isBoss,
      pagination: {
        current: 1,
        size: 10,
        total: 0,
        searchKey: "",
        recommendStatus: null,
        sortId: null,
        labelId: null
      },
      articles: [],
      sorts: [],
      labels: [],
      labelsTemp: []
    };
  },
  computed: {},
  watch: {
    'pagination.sortId': function paginationSortId(newVal) {
      this.pagination.labelId = null;

      if (!this.$common.isEmpty(newVal) && !this.$common.isEmpty(this.labels)) {
        this.labelsTemp = this.labels.filter(function (l) {
          return l.sortId === newVal;
        });
      }
    }
  },
  created: function created() {
    this.getArticles();
    this.getSortAndLabel();
  },
  mounted: function mounted() {},
  methods: {
    getSortAndLabel: function getSortAndLabel() {
      var _this = this;

      this.$http.get(this.$constant.baseURL + "/webInfo/listSortAndLabel").then(function (res) {
        if (!_this.$common.isEmpty(res.data)) {
          _this.sorts = res.data.sorts;
          _this.labels = res.data.labels;
        }
      }).catch(function (error) {
        _this.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    clearSearch: function clearSearch() {
      this.pagination = {
        current: 1,
        size: 10,
        total: 0,
        searchKey: "",
        recommendStatus: null,
        sortId: null,
        labelId: null
      };
      this.getArticles();
    },
    getArticles: function getArticles() {
      var _this2 = this;

      var url = "";

      if (this.isBoss) {
        url = "/admin/article/boss/list";
      } else {
        url = "/admin/article/user/list";
      }

      this.$http.post(this.$constant.baseURL + url, this.pagination, true).then(function (res) {
        if (!_this2.$common.isEmpty(res.data)) {
          _this2.articles = res.data.records;
          _this2.pagination.total = res.data.total;
        }
      }).catch(function (error) {
        _this2.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    handlePageChange: function handlePageChange(val) {
      this.pagination.current = val;
      this.getArticles();
    },
    searchArticles: function searchArticles() {
      this.pagination.total = 0;
      this.pagination.current = 1;
      this.getArticles();
    },
    changeStatus: function changeStatus(article, flag) {
      var _this3 = this;

      var param;

      if (flag === 1) {
        param = {
          articleId: article.id,
          viewStatus: article.viewStatus
        };
      } else if (flag === 2) {
        param = {
          articleId: article.id,
          commentStatus: article.commentStatus
        };
      } else if (flag === 3) {
        param = {
          articleId: article.id,
          recommendStatus: article.recommendStatus
        };
      }

      this.$http.get(this.$constant.baseURL + "/admin/article/changeArticleStatus", param, true).then(function (res) {
        if (flag === 1) {
          _this3.$message({
            duration: 0,
            showClose: true,
            message: "修改成功！注意，文章不可见时必须设置密码才能访问！",
            type: "warning"
          });
        } else {
          _this3.$message({
            message: "修改成功！",
            type: "success"
          });
        }
      }).catch(function (error) {
        _this3.$message({
          message: error.message,
          type: "error"
        });
      });
    },
    handleDelete: function handleDelete(item) {
      var _this4 = this;

      this.$confirm('确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'success',
        center: true
      }).then(function () {
        _this4.$http.get(_this4.$constant.baseURL + "/article/deleteArticle", {
          id: item.id
        }, true).then(function (res) {
          _this4.pagination.current = 1;

          _this4.getArticles();

          _this4.$message({
            message: "删除成功！",
            type: "success"
          });
        }).catch(function (error) {
          _this4.$message({
            message: error.message,
            type: "error"
          });
        });
      }).catch(function () {
        _this4.$message({
          type: 'success',
          message: '已取消删除!'
        });
      });
    },
    handleEdit: function handleEdit(item) {
      this.$router.push({
        path: '/postEdit',
        query: {
          id: item.id
        }
      });
    }
  }
};